<template>
    <div>
        <div style="margin-top: 80px" class="info-events">
            <h2>California Wines Events</h2>
            <p>Welcome to the new home for California Wines online wine tastings.</p>
        </div>

        
        <div v-if="this.isFetching == false">
            <div class="home-events events-upcoming" v-if="this.liveEntriesFetched.length > 0">
                <div class="section-header">
                    <h1>Now on</h1>
                </div>
                <div class="wrap">
                    <div class="events-feed">
                        <div class="event" v-for="(entry, item) in this.liveEntriesFetched" v-bind:key="item">
                            <div class="event-thumbnail">
                                <img v-if="entry.thumbnail[0]" :src="entry.thumbnail[0].url"  @click="goToEvent(entry.slug, entry.id)">
                            </div>

                            <div class="event-details">
                                <h2 class="event-title">{{entry.title}}</h2>
                                <h2 class="event-date">{{formatDate( entry.eventDate, entry.eventStartTime)}}</h2>
                                <p class="event-desc">{{entry.postSummary}}</p>
                                <a class="blue" @click="goToEvent(entry.slug, entry.id)">enter live event</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="home-events events-upcoming" v-if="this.futureEntriesFetched.length > 0">
                <div class="section-header">
                    <router-link to="/events/upcoming">
                        <h1>upcoming events</h1>
                    </router-link>
                </div>
                <div class="wrap">
                    <div class="events-feed">
                        <div class="event" v-for="(entry, item) in this.futureEntriesFetched" v-bind:key="'A' + item">
                            <div class="event-thumbnail">
                                <img v-if="entry.thumbnail[0]" :src="entry.thumbnail[0].url"  @click="goToEvent(entry.slug, entry.id)">
                            </div>

                            <h2 class="event-title">{{entry.title}}</h2>
                            <h2 class="event-date">{{formatDate( entry.eventDate, entry.eventStartTime)}}</h2>
                            <p class="event-desc">{{entry.postSummary}}</p>

                            <a @click="goToEvent(entry.slug, entry.id)">view upcoming event</a>
                        </div>
                    </div>
                    <div class="see-more">
                        <router-link to="/events/upcoming">SEE MORE UPCOMING EVENTS</router-link>
                    </div>
                </div>
            </div>
            <div class="home-events events-past" v-if="this.pastEntriesFetched.length > 0">
                <div class="section-header">
                    <router-link to="/events/past">
                        <h1>past events</h1>
                    </router-link>
                </div>
                <div class="wrap">
                    <div class="events-feed">

                        <div class="event" v-for="(entry, item) in this.pastEntriesFetched" v-bind:key="'B' + item">
                            <div class="event-thumbnail">
                                <img v-if="entry.thumbnail[0]" :src="entry.thumbnail[0].url"  @click="goToEvent(entry.slug, entry.id)">
                            </div>

                            <h2 class="event-title">{{entry.title}}</h2>
                            <h2 class="event-date">{{formatDate( entry.eventDate, entry.eventStartTime)}}</h2>
                            <p class="event-desc">{{entry.postSummary}}</p>

                            <a @click="goToEvent(entry.slug, entry.id)">view past event</a>
                        </div>
                    </div>
                    <div class="see-more">
                        <router-link to="/events/past">SEE MORE PAST EVENTS</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div v-else class="lds-ring">
            <img class="loading-gif" src="@/assets/img/loadingwheel.gif" alt="">
        </div>
    </div>
</template>
<script>

import store from '@/store/index.js'
import gql from 'graphql-tag';
import moment from 'moment-timezone';
import $ from 'jquery';

export default {
    name: "Events",
    components: {
    },

    data() {
        return {
            isFetching: true,
            isRefreshing: false,
            pastEntriesFetched: [],
            futureEntriesFetched: [],
            liveEntriesFetched: [],
        }
    },

    mounted() {
        console.log("events mounted");

        var query = this.$apollo.queries;
        query.entries.skip = false;

        setInterval(function() {
            console.log("Query Refresh");
            query.entries.skip = false;
            query.entries.refresh()
        }, 60 * 1000);

        $('.wrapper').attr('style', '');
        $('.wrapper').css('position', 'unset');
    },

    methods: {
        formatDate(date, start) {
            var displayDate = moment.utc(date).format('ddd, MMMM DD, ') +  moment(start).format('HH:mm')

            return displayDate;
        },

        goToEvent(slug, id) {
            store.commit('setvisitedEvent', slug);
            store.commit('setvisitedEventId', id);

            this.$router.push({name:'event', params:{slug:slug, eventId: id}})
        },
    },

    apollo: {
        entries: {
            client: 'craft',
            query: gql`query {
                entries(status: "disabled") {
                    ... on videoConferences_videoConferences_Entry {
                        id
                        slug
                        title
                        postSummary
                        imageBanner {
                            width
                            url
                        }
                        thumbnail {
                            width
                            url @transform (width: 1200, immediately: true)
                        }
                        eventDate
                        eventStartTime
                        eventEndTime
                    }
                }
            }`,

            // pollInterval: 60000, //ms
            fetchPolicy: 'network-only',

            skip () {
                console.log("skipping");
                return this.skipQuery
            },

            result ({ data, loading }) {
                console.log("Results");

                if (!loading) {
                    var entriesArray    = [];
                    var pastEntries     = [];
                    var futureEntries   = [];
                    var liveEntries     = [];

                    var previousEntryDate;

                    // Filter out any empty entries
                    data.entries.forEach(entry => {
                        if (entry.id) {
                            entriesArray.push(entry);
                        }
                    });

                    entriesArray.forEach(entry => {
                        var start   = entry.eventStartTime;
                        var end     = entry.eventEndTime;
                        var date    = entry.eventDate;

                        // console.log("---------------------------------");
                        // console.log("-----------EVENT START-----------");
                        // console.log("---------------------------------");
                        // console.log("RAW START TIME");
                        // console.log(start);
                        // console.log(date);

                        start   = moment.utc(start).format('HH:mm');
                        end     = moment.utc(end).format('HH:mm');
                        date    = moment.utc(date).format('L');

                        // console.log(date);

                        // console.log("MOMENT START TIME");
                        // console.log(start);
                        // console.log("MOMENT START TIME TZ");
                        // console.log(moment(start).tz("Europe/London"));

                        var startDate   = moment(date + ' ' + start, 'MM/DD/YYYY HH:mm');
                        var endDate     = moment(date + ' ' + end, 'MM/DD/YYYY HH:mm');

                        // console.log("MOMENT STARTDATE TIME");
                        // console.log(startDate);

                        // console.log("UTC TIME");
                        var timeUTC = moment.tz("Europe/London").format('MM/DD/YYYY HH:mm');
                        // console.log(timeUTC);

                        // Remmove html tags from description
                        entry.postSummary = entry.postSummary.replace(/<(?!a\s*\/?)[^>]+>/g, '')

                        if (moment(endDate) < moment(timeUTC)) {
                            
                            if (!previousEntryDate) {
                                previousEntryDate = startDate;
                                pastEntries.push(entry);
                            } else if (moment(startDate) > moment(previousEntryDate)) {
                                pastEntries.unshift(entry);
                                previousEntryDate = startDate;
                            } else {
                                pastEntries.push(entry);
                                previousEntryDate = startDate;
                            }

                        } 
                        
                        else if (moment(startDate) > moment(timeUTC)) {
                            console.log("Future event");

                            futureEntries.push(entry);
                        } 
                        
                        else if (moment(startDate) < moment(timeUTC) && moment(endDate) > moment(timeUTC)) {
                            console.log("Live event");

                            liveEntries.push(entry);
                        }

                        // console.log("---------------------------------");
                        // console.log("------------EVENT END------------");
                        // console.log("---------------------------------");
                    });

                    this.isFetching = false;
                    this.$apollo.queries.entries.skip = true;

                    this.pastEntriesFetched     = pastEntries;
                    this.futureEntriesFetched   = futureEntries;
                    this.liveEntriesFetched     = liveEntries;
                }
            },
        }
    }
}
</script>